import { Box, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";

interface ElipsisWrappedTypographyProps {
  readonly children: ReactNode,
  readonly Width: string | number,
  readonly [rest: string]: unknown
}

export const ElipsisWrappedTypography = (props: ElipsisWrappedTypographyProps) => {
  const { children, Width, ...rest } = props;
  const noWrap = rest["noWrap"];
  const content =
    <Tooltip title={children}>
      <Typography {...rest} >{children}</Typography>
    </Tooltip>;

  if (noWrap) {
    return <Box sx={{ overflow: noWrap ? "hidden" : "", textOverflow: noWrap ? "ellipsis" : "", width: Width, whiteSpace: noWrap ? "nowrap" : "wrap" }} >{content}  </Box>;
  } else {
    return content;
  }

};
