import { RefObject, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider, InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

import { Cancel, Check, Clear, DeleteForever, Download, FileUploadOutlined, MoreVert } from "@mui/icons-material";
import { ApiEnabledPage, FabricFile, FileType, ProjectData, RenderJobInfo } from "./types";
import { ArrayEquals, AuthHelper, GetOrderByDirection, GetOrderByIndex, GetPage, GetPageSize, IsNotNull, IsNullOrEmpty, RenderJobStatus, RenderJobStatusToColor, RenderJobStatusToName, SetCurrentPage, SetOrderByDirection, SetOrderByIndex, SetPageSize } from "../utils/";
import { CircularProgressWithLabel, SortableSearchableTable, TableColumnData, TimeSince, TopBar } from "../components";
import { CURRENT_GEM_FILE_MAJOR, GEM_FILE_MAGIC } from "./constants";
import { UnzipFileInfo, unzipSync, unzip } from "fflate";
import { sha256 } from 'js-sha256';
import { useSearchParams } from "react-router-dom";

const DEFAULT_PAGE_SIZE: number = 10;

interface DragDropProps {
  readonly onFileDragDivClick: () => void,
  readonly dropRef: RefObject<HTMLDivElement>,
  readonly inputRef: RefObject<HTMLInputElement>,
  readonly allowFileSelect: boolean,
  readonly onFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly largeSize: string,
  readonly fileFormat: string,
  readonly className?: string,
  readonly disabled?: boolean
}

const DragDropBox = (props: DragDropProps) => {
  const {
    onFileDragDivClick,
    dropRef,
    inputRef,
    allowFileSelect,
    onFileSelected,
    largeSize,
    fileFormat,
    className,
    disabled } = props;

  return (
    <Box
      onClick={onFileDragDivClick}
      ref={disabled ? null : dropRef}
      sx={{ bgcolor: 'background.paper' }}
      className={className ? `${className}` : ""}>
      <Stack>

        <FileUploadOutlined color={disabled ? "disabled" : "inherit"} sx={{
          width: largeSize,
          height: largeSize,
          ml: "0.5vmin",
        }} className="fileUploadIcon" />
      </Stack>

      {
        allowFileSelect
          ? <input
            disabled={disabled}
            onChange={onFileSelected}
            accept={fileFormat}
            style={{ display: "none" }}
            ref={inputRef} type="file" />
          : ""
      }
    </Box>);
};

interface ArahWeaveImageUploadRowProps {
  readonly fileName: string,
  readonly type: FileType,
  readonly description: string,
  readonly inputRef: RefObject<HTMLInputElement>,
  readonly allowFileSelect: boolean,
  readonly makeDragRefForImage: (name: string, type: FileType) => RefObject<HTMLDivElement>,
  readonly fileUploadClick: () => void,
  readonly onFileSelectionImage: (ev: React.ChangeEvent<HTMLInputElement>, name: string, type: FileType) => void,
  readonly uploaded: ProjectData
}

const ArahWeaveImageUploadRow = (props: ArahWeaveImageUploadRowProps) => {
  const {
    fileName,
    type,
    description,
    inputRef,
    allowFileSelect,
    makeDragRefForImage,
    fileUploadClick,
    onFileSelectionImage,
    uploaded } = props;

  const fileNameNull = IsNullOrEmpty(fileName);
  let fileExt: string;

  if (!fileNameNull && fileName.includes(".")) {
    fileExt = `.${fileName.split('.').pop() as string}`;
  } else {
    fileExt = ".png";
  }

  if (!fileExt) {
    fileExt = ".png";
  }
  const goodMark = <Check color="success" />;
  const badMark = <Clear color="error" />;
  const dragDropBox = <DragDropBox
    disabled={fileNameNull}
    onFileDragDivClick={fileUploadClick}
    dropRef={makeDragRefForImage(fileName, type)}
    inputRef={inputRef}
    allowFileSelect={allowFileSelect}
    onFileSelected={(ev) => onFileSelectionImage(ev, fileName, type)}
    largeSize="4VMIN"
    fileFormat={fileExt} />;

  return (
    <TableRow>

      <TableCell>
        <Typography color={fileNameNull ? "GrayText" : "inherit"} alignContent="center">{description}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={fileNameNull ? "GrayText" : "inherit"}
          alignContent="center">{!fileNameNull ? fileName : "None"}
        </Typography>
      </TableCell>

      {uploaded.Json ? <TableCell> {dragDropBox} </TableCell> : ""}

      <TableCell>
        {fileNameNull ? <Clear color="error" /> : <Check color="success" />}
      </TableCell>
      <TableCell>
        {uploaded.GemFile
          ? goodMark
          : uploaded.Images
            ? uploaded.Images[fileName]
              ? goodMark
              : badMark
            : badMark}
      </TableCell>

    </TableRow>);

};

interface ArahWeaveImageUploadProps {
  readonly makeDragRefForImage: (name: string, type: FileType) => RefObject<HTMLDivElement>
  readonly fabricFile: FabricFile,
  readonly isDraggingFile: boolean,
  readonly onFileSelectionImage: (ev: React.ChangeEvent<HTMLInputElement>, name: string, type: FileType) => void
  readonly fabricUploadedData: ProjectData

}

const ArahWeaveImageUpload = (props: ArahWeaveImageUploadProps) => {

  const {
    makeDragRefForImage,
    fabricFile,
    isDraggingFile,
    onFileSelectionImage,
    fabricUploadedData } = props;

  const weaveRef = useRef<HTMLInputElement>(null);
  const weftFilCoupeRef = useRef<HTMLInputElement>(null);
  const warpFilCoupeRef = useRef<HTMLInputElement>(null);

  const refClick = (ref: RefObject<HTMLInputElement>) => {

    if (ref.current) {
      ref.current.click();
    }

  };
  return (
    <Table >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography>File Purpose</Typography>
          </TableCell>
          <TableCell>
            <Typography>Filename</Typography>
          </TableCell>
          {fabricUploadedData.Json ? <TableCell><Typography>Upload</Typography></TableCell> : ""}
          <TableCell>
            <Typography>Required</Typography>
          </TableCell>
          <TableCell>
            <Typography>Uploaded</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <ArahWeaveImageUploadRow
          fileName={fabricFile.Weave}
          type={FileType.WeavePattern}
          description="Weave Pattern"
          inputRef={weaveRef}
          allowFileSelect={!isDraggingFile}
          makeDragRefForImage={makeDragRefForImage}
          fileUploadClick={() => refClick(weaveRef)}
          onFileSelectionImage={onFileSelectionImage}
          uploaded={fabricUploadedData}
        />

        <ArahWeaveImageUploadRow
          fileName={fabricFile.Weft.FilCoupe}
          type={FileType.WeftFilCoupe}
          description="Weft Fil Coupe Pattern"
          inputRef={weftFilCoupeRef}
          allowFileSelect={!isDraggingFile}
          makeDragRefForImage={makeDragRefForImage}
          fileUploadClick={() => refClick(weftFilCoupeRef)}
          onFileSelectionImage={onFileSelectionImage}
          uploaded={fabricUploadedData}
        />

        <ArahWeaveImageUploadRow
          fileName={fabricFile.Warp.FilCoupe}
          type={FileType.WarpFilCoupe}
          description="Warp Fil Coup Pattern"
          inputRef={warpFilCoupeRef}
          allowFileSelect={!isDraggingFile}
          makeDragRefForImage={makeDragRefForImage}
          fileUploadClick={() => refClick(warpFilCoupeRef)}
          onFileSelectionImage={onFileSelectionImage}
          uploaded={fabricUploadedData}
        />

      </TableBody>
    </Table>);

};

interface HomePageProps extends ApiEnabledPage {
}

const logout = (authHelper: AuthHelper, setIsLoggedIn: (state: boolean) => void) => {
  authHelper.Logout();
  setIsLoggedIn(false);

};

export const HomePage = (props: HomePageProps) => {
  const { baseApiUrl, authHelper } = props;
  const [isDraggingFile, setIsDraggingFile] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [renderResolution, setRenderResolution] = useState<number>(2048);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const [userJobs, setUserJobs] = useState<RenderJobInfo[]>([]);
  const [numJobs, setNumJobs] = useState<number>(-1);
  const [downloading, setDownloading] = useState<string>("");

  const [operationsMenuAnchor, setOperationsMenuAnchor] = useState<null | HTMLElement>(null);
  const [operationsMenuSelectedJob, setOperationsMenuSelectedJob] = useState<null | RenderJobInfo>(null);
  const [fabricObj, setFabricObj] = useState<FabricFile | null>(null);

  const [weaveImageNeeded, setWeaveImageNeeded] = useState<boolean>(true);
  const [warpFilCoupeImageNeeded, setWarpFilCoupeImageNeeded] = useState<boolean>(true);
  const [weftFilCoupeImageNeeded, setWeftFilCoupeImageNeeded] = useState<boolean>(true);
  const [searchParams,] = useSearchParams();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(authHelper.IsLoggedIn());
  const loginState = authHelper.GetLoginState();

  const navigate = useNavigate();
  const pageSizeOptions: number[] = [5, 10, 25, 100];
  const page = GetPage(numJobs, pageSizeOptions, DEFAULT_PAGE_SIZE, searchParams);
  const pageSize = GetPageSize(pageSizeOptions, DEFAULT_PAGE_SIZE, searchParams);

  const jobsTableConfig: TableColumnData<RenderJobInfo>[] = [{
    Index: 0,
    Sortable: true,
    DisplayName: "Name",
    PropertyName: "Name",
    Width: "1vw",
    ValueGetter(job) {
      return <Typography>{job.Name}</Typography>;
    },
  }, {
    Index: 1,
    Sortable: true,
    DisplayName: "Created",
    PropertyName: "Submitted",
    Width: "1vw",
    ValueGetter: (job) => <TimeSince Date={job.Submitted} Width="1vw" />
  }, {
    Index: 2,
    Sortable: true,
    DisplayName: "Status",
    PropertyName: "Status",
    Width: "1vw",
    ValueGetter(job) {
      return <Chip label={RenderJobStatusToName(job.Status)} color={RenderJobStatusToColor(job.Status)} />;
    },
  }, {
    Index: 3,
    Sortable: true,
    DisplayName: "Progress",
    PropertyName: "Completion",
    Width: "1vw",
    ValueGetter: (job) => <CircularProgressWithLabel color={job.Completion === 1.0 ? "success" : "primary"} variant="determinate" value={(job.Completion * 100)} />

  }, {
    Index: 4,
    Sortable: true,
    DisplayName: "More Information",
    PropertyName: "StatusMessage",
    Width: "1vw",
    ValueGetter: (job) => <Typography>{job.StatusMessage}</Typography>

  }, {
    Index: 5,
    Sortable: false,
    DisplayName: "Download",
    Width: "1vw",
    ValueGetter: (job) => {
      const content =
        <Button
          disabled={downloading === job.id || job.Status !== RenderJobStatus.Complete}
          onClick={() => OnDownloadResult(job)}
          variant="contained">
          {downloading === job.id
            ? <CircularProgress />
            : <Download />}
        </Button>;
      return content;

    }
  }, {
    Index: 6,
    Sortable: false,
    Width: "1vw",
    ValueGetter: (job) => <Button onClick={(evt) => onOperationsButtonClicked(evt, job)}><MoreVert /></Button>

  }];

  const orderByIndex = GetOrderByIndex(jobsTableConfig.length, 1, searchParams);
  const orderByDirection = GetOrderByDirection(searchParams, "desc");

  let supportedResolutions: number[];
  const isDebug = false;//window.location.hostname === "localhost";
  if (isDebug) {
    supportedResolutions = [128, 256, 512, 1024, 2048, 4096, 8192];
  } else {
    supportedResolutions = [1024, 2048, 4096, 8192];
  }

  const operationsMenuOpened = Boolean(operationsMenuAnchor);

  const numSeconds: number = 5;
  const secondsToMili: number = 1000;

  const onClickCancelMenuItem = async () => {
    if (loginState) {

      const apiKey = loginState.apiKey;
      if (operationsMenuSelectedJob && apiKey) {
        await fetch(baseApiUrl + "CancelJob", {
          "method": "POST",
          "body": JSON.stringify({ JobId: operationsMenuSelectedJob.id }),
          headers: {
            'Authentication': apiKey,
          },
        });
      }
    }
    setOperationsMenuAnchor(null);

  };

  const onClickDeleteMenuItem = async () => {
    if (loginState) {

      const apiKey = loginState.apiKey;
      if (operationsMenuSelectedJob && apiKey) {

        await fetch(baseApiUrl + "DeleteJob", {
          "method": "POST",
          "body": JSON.stringify({ JobId: operationsMenuSelectedJob.id }),
          headers: {
            'Authentication': apiKey,
          },
        });

      }
    }

    setOperationsMenuAnchor(null);
  };

  useEffect(() => {
    if (loginState) {

      const queryParams = "status=Submitted&status=Assigned&status=Queued&status=InProgress&status=Complete&status=Crashed";

      fetch(baseApiUrl + `GetNumJobs?${queryParams} `, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authentication: loginState.apiKey
        }
      }).then(res => {

        if (res.status === 200) {
          res.json().then(json => {
            setNumJobs(+json.Count);

          });
        } else if (res.status === 401) {
          logout(authHelper, setIsLoggedIn);
        }
      });
    }

  }, [userJobs]);

  useEffect(() => {

    let anyInProgress: boolean = false;
    userJobs.map((job => {
      if (job.Status !== RenderJobStatus.Complete && job.Status !== RenderJobStatus.Crashed) {
        anyInProgress = true;
      }

    }));

    let interval: number | null = null;

    if (anyInProgress) {

      interval = setInterval(() => {
      }, numSeconds * secondsToMili);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };

  }, []);

  const OnDownloadResult = async (job: RenderJobInfo): Promise<void> => {
    if (loginState) {

      const apiKey = loginState.apiKey;
      if (apiKey) {

        setDownloading(job.id);
        const result = await fetch(baseApiUrl + "RequestDownloadUri", {
          method: "POST",
          headers: {
            'Authentication': apiKey,
          },
          body: JSON.stringify({ JobId: job.id }),
        });
        const uriObj = await result.json();

        const uri: string = uriObj.Uri;

        const storageResult = await fetch(uri);

        const blob = await storageResult.blob();
        const array = await blob.arrayBuffer();
        const u8Array = new Uint8Array(array);

        let numFiles: number = 0;

        unzip(u8Array, {
          filter: () => {
            numFiles += 1;
            return false;
          }
        }, () => {
          if (numFiles > 1) {
            const link = document.createElement("a");
            link.download = `${job.Name}.zip`;
            link.href = uri;
            link.target = "_blank";
            link.style.display = 'none';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloading("");

          } else {
            let unzippedFilename: string = "";

            unzip(u8Array, {
              filter: (file) => {
                unzippedFilename = file.name;
                return true;
              }
            }, (error, data) => {
              if (!error) {

                const array = data[unzippedFilename];

                const link = document.createElement("a");
                const reader = new FileReader();
                reader.readAsDataURL(new Blob([array]));
                reader.onloadend = () => {
                  if (typeof reader.result === "string") {
                    link.setAttribute("href", reader.result);
                    const fileExt = unzippedFilename.split('.').pop();

                    link.setAttribute('download', `${job.Name}.${fileExt}`);
                    link.click();
                    setDownloading("");
                  }
                };

              }

            });
          }
        });

      }
    }

  };

  const fetchJobs = async () => {
    if (loginState) {

      setUserJobs([]);

      const fieldName = jobsTableConfig[orderByIndex].PropertyName;

      const queryParams = `page=${page}` +
        `&pageSize=${pageSize}&orderBy=${fieldName}&Direction=${orderByDirection.toUpperCase()}` +
        `&status=Submitted&status=Assigned&status=Queued&status=InProgress&status=Complete&status=Crashed`;

      const apiKey = loginState.apiKey;
      if (apiKey) {
        const res = await fetch(baseApiUrl + "GetJobs?" + queryParams, {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Authentication': apiKey,
          },
        });

        if (res.status === 200) {
          const data = await res.json();
          setUserJobs(data);
        } else if (res.status === 401) {
          logout(authHelper, setIsLoggedIn);

        }
      }
    }
  };

  useEffect(() => {
    fetchJobs();

  }, [numJobs, page, pageSize, orderByIndex, orderByDirection]);

  const onDragDropJson = (event: DragEvent) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.dataTransfer && checkAllFiles(event)) {
      handleProjectFileSelected(event.dataTransfer.files);
    }

    setIsDraggingFile(false);
  };

  const onDragDropImage = (event: DragEvent, name: string, type: FileType) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.dataTransfer && checkAllFiles(event) && projectData && projectData.Images) {
      handleImageFilesSelected(event.dataTransfer.files, name, type);
    }
  };

  const onOperationsButtonClicked = (event: React.MouseEvent<HTMLElement>, job: RenderJobInfo): void => {
    setOperationsMenuAnchor(event.currentTarget);
    setOperationsMenuSelectedJob(job);
  };

  const checkAllFiles = (event: DragEvent): boolean => {
    let isFiles: boolean = false;

    if (event.dataTransfer && event.dataTransfer.types && event.dataTransfer.types.length > 0) {
      for (let i = 0; i < event.dataTransfer.types.length; i++) {
        const type = event.dataTransfer.types[i];

        isFiles = (type === "Files");
        if (!isFiles) {
          break;
        }
      }
    } else {
      return false;
    }

    return isFiles;

  };

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (checkAllFiles(event)) {
      setIsDraggingFile(true);
    }
  };

  const onDragEnd = (event: DragEvent) => {
    event.preventDefault();
    event.stopImmediatePropagation();

    setIsDraggingFile(false);
  };

  const dropRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (dropRef.current) {
      dropRef.current.addEventListener('dragleave', onDragEnd, false);
      dropRef.current.addEventListener('dragover', onDragOver, false);
      dropRef.current.addEventListener('drop', onDragDropJson, false);
    }
  });

  const makeDragRefForImage = (name: string, type: FileType): RefObject<HTMLDivElement> => {

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref.current) {
        ref.current.addEventListener('dragleave', onDragEnd, false);
        ref.current.addEventListener('dragover', onDragOver, false);
        ref.current.addEventListener('drop', (ev) => onDragDropImage(ev, name, type));
      }

    });

    return ref;
  };

  const fileInputRefJson = useRef<HTMLInputElement>(null);

  const onFileDragDivClick = () => {
    if (fileInputRefJson.current) {
      fileInputRefJson.current.click();
    }
  };

  const onFileSelectedJson = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      handleProjectFileSelected(event.target.files);
    }
  };

  const onFileSelectionImage = (event: React.ChangeEvent<HTMLInputElement>, name: string, type: FileType): void => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      handleImageFilesSelected(event.target.files, name, type);
    }

  };

  const handleImageFilesSelected = (files: FileList, name: string, type: FileType) => {
    if (files.length !== 1) {
      throw new Error("Expected one file");
    }
    if (projectData) {

      const newData: ProjectData = {
        Name: projectData.Name,
        Json: projectData.Json,
        Images: projectData.Images,
      };

      if (!newData.Images) {
        newData.Images = {};
      }

      if (files.length > 1) {
        return;
      }

      newData.Images[name] = files[0];

      setProjectData(newData);

      switch (type) {
        case FileType.WeavePattern:
          setWeaveImageNeeded(false);
          break;

        case FileType.WeftFilCoupe:
          setWeftFilCoupeImageNeeded(false);
          break;

        case FileType.WarpFilCoupe:
          setWarpFilCoupeImageNeeded(false);
          break;
      }
    }
  };

  const validateGem = async (gem: File): Promise<[boolean, Uint8Array]> => {
    const buffer = await gem.arrayBuffer();
    const magicData = new Int8Array(buffer, 0, GEM_FILE_MAGIC.length);

    const sizeOfUInt32 = 4;
    const sizeOfUInt64 = 8;
    const sizeOfSha256 = 32;

    const magicEquals = ArrayEquals(magicData, GEM_FILE_MAGIC);

    const versionData = new Int32Array(buffer, GEM_FILE_MAGIC.length, 2);
    const major = versionData[0];

    const zipSizeData = new BigUint64Array(buffer, GEM_FILE_MAGIC.length + sizeOfUInt32 * 2, 1);
    const zipSize = zipSizeData[0];
    const fileData = new Uint8Array(buffer, 0, gem.size - sizeOfSha256);

    const sha = sha256.create();
    sha.update(fileData);
    const computedHash = sha.digest();
    const headerSize = GEM_FILE_MAGIC.length + sizeOfUInt32 * 2 + sizeOfUInt64;

    const zipData = fileData.slice(headerSize, gem.size - sizeOfSha256);

    const fileHash = Array.from(new Uint8Array(buffer.slice(buffer.byteLength - sizeOfSha256, buffer.byteLength)));
    const hashEquals = ArrayEquals(fileHash, computedHash);

    const validated = zipSize > 0 && CURRENT_GEM_FILE_MAJOR >= major && hashEquals && magicEquals;

    return [validated, zipData];
  };

  const handleProjectFileSelected = async (files: FileList) => {
    if (files.length !== 1) {
      throw new Error("Expected one file");
    }

    if (files[0].type === "application/json") {
      const json = files[0];
      if (IsNotNull(json)) {
        setProjectData({
          Name: json.name,
          Json: json as File,
        });

        setIsModalOpen(true);
        const reader: FileReader = new FileReader();
        reader.readAsText(json as File);
        reader.onload = (e) => {
          if (!e.target || !e.target.result) {
            return;
          }

          if (typeof e.target.result === 'string') {
            const file: FabricFile = JSON.parse(e.target.result);
            setFabricObj(file);
            if (file) {
              setWeaveImageNeeded(!IsNullOrEmpty(file.Weave));
              setWarpFilCoupeImageNeeded(!IsNullOrEmpty(file.Warp.FilCoupe));
              setWeftFilCoupeImageNeeded(!IsNullOrEmpty(file.Weft.FilCoupe));
            }
          }
        };
      }
    } else if (files[0].name.endsWith(".gem")) {
      const zip = files[0];
      const [validated, zipBytes] = await validateGem(zip);

      if (validated) {
        const unzipped = unzipSync(zipBytes, {
          filter(file: UnzipFileInfo) {
            return file.name === "Fabric.json";
          },
        });

        const decoder = new TextDecoder();
        const jsonData = decoder.decode(unzipped["Fabric.json"]);
        const projectData: FabricFile = JSON.parse(jsonData);

        setIsModalOpen(true);
        setFabricObj(projectData);
        setWeaveImageNeeded(false);
        setWarpFilCoupeImageNeeded(false);
        setWeftFilCoupeImageNeeded(false);
        setProjectData({
          Name: zip.name,
          GemFile: zip,
        });

      }
    } else {
      return;
    }
  };

  const onSubmitNewJob = async () => {

    if (projectData && ((projectData.Json && projectData.Images) || projectData.GemFile)) {
      if (loginState) {

        const apiKey = loginState.apiKey;

        if (apiKey) {

          setIsSubmitButtonDisabled(true);
          const jobCreateRes = await fetch(baseApiUrl + "CreateJob", {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Authentication': apiKey,
            },
            body: JSON.stringify({
              name: projectData.Name,
              resolution: renderResolution,
            }),
          });

          if (jobCreateRes.status === 200) {
            const jobDetails = await jobCreateRes.json();
            const formData = new FormData();
            if (projectData.GemFile) {
              formData.append("gem", projectData.GemFile);
            } else if (projectData.Images && projectData.Json) {

              for (const fileName in projectData.Images) {
                formData.append(fileName, projectData.Images[fileName]);
              }
              formData.append("json", projectData.Json);
            }

            formData.append("jobId", jobDetails.Id);
            const jobDataSubmission = await fetch(baseApiUrl + "SubmitJobData", {
              "method": "POST",
              "body": formData,
              headers: {
                'Accept': 'application/json',
                'Authentication': apiKey,
              },
            });

            if (jobDataSubmission.status === 200) {
              setIsModalOpen(false);
            } else if (jobDataSubmission.status === 401) {
              logout(authHelper, setIsLoggedIn);
            }
          } else if (jobCreateRes.status === 401) {
            logout(authHelper, setIsLoggedIn);
          }
          setIsSubmitButtonDisabled(false);
        }
      }
    }
  };

  if (!isLoggedIn) {
    return <Navigate to="/Login" />;
  }

  const onClickSort = async (Index: number) => {
    if (Index === orderByIndex) {
      SetOrderByDirection(searchParams, orderByDirection === "asc" ? "desc" : "asc", navigate);
      SetOrderByIndex(Index, jobsTableConfig.length, searchParams, navigate);

    } else {
      SetOrderByDirection(searchParams, orderByDirection, navigate);
      SetOrderByIndex(Index, jobsTableConfig.length, searchParams, navigate);

    }

  };

  const anyFilesNeeded = weaveImageNeeded || warpFilCoupeImageNeeded || weftFilCoupeImageNeeded;
  return (
    <>
      <TopBar authHelper={authHelper} logout={() => {
        authHelper.Logout();
        setIsLoggedIn(false);
      }} />
      <Stack className="centeredBox">
        <Typography variant="h2">Render Jobs</Typography>

        <SortableSearchableTable
          className={`jobList jobList-max-dims jobList-${isDebug ? "debug" : "prod"}-dims dottedBorder`}
          DataItems={userJobs}
          NumTotalItems={numJobs}
          PageSize={pageSize}
          PageSizeOptions={pageSizeOptions}
          SetPageSize={(val: number) => SetPageSize(val, pageSizeOptions, DEFAULT_PAGE_SIZE, searchParams, navigate)}
          Page={page}
          SetPage={(val: number) => SetCurrentPage(
            val,
            numJobs,
            searchParams,
            pageSizeOptions,
            DEFAULT_PAGE_SIZE,
            navigate)}
          OnClickSort={(val) => onClickSort(val)}
          OrderBy={{ Dir: orderByDirection, Index: orderByIndex }}
          TableColumnData={jobsTableConfig}
        />

        <Menu
          open={operationsMenuOpened}
          anchorEl={operationsMenuAnchor}
          onClose={() => setOperationsMenuAnchor(null)}>
          <MenuItem
            onClick={onClickDeleteMenuItem}
            disabled={operationsMenuSelectedJob?.Status !== RenderJobStatus.Complete}>

            <ListItemIcon>
              <DeleteForever />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Delete</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={onClickCancelMenuItem}
            disabled={
              operationsMenuSelectedJob?.Status === RenderJobStatus.Complete
              || operationsMenuSelectedJob?.Status === RenderJobStatus.Deleted
            }>
            <ListItemIcon>
              <Cancel />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Cancel</Typography>
            </ListItemText>
          </MenuItem>
        </Menu>

        {
          isDebug ?
            <>
              <DragDropBox
                onFileDragDivClick={onFileDragDivClick}
                dropRef={dropRef}
                inputRef={fileInputRefJson}
                onFileSelected={onFileSelectedJson}
                allowFileSelect={!isDraggingFile && !projectData}
                largeSize="16VMIN"
                fileFormat=".json,.gem"
                className="uploadJobBox dottedBorder"
              />

              <Modal open={isModalOpen}>
                <Box sx={{ bgcolor: 'background.paper' }} className="modalRoot">
                  <Typography sx={{ mt: "1vmin" }}
                    variant="h4"
                    textAlign={"center"}
                    component={"h2"}>Start new render job
                  </Typography>
                  <Stack className="uploadStack" direction="row">
                    <Typography>{projectData?.Name}</Typography >
                    <InputLabel sx={{ marginRight: "0" }} id="SelectLabel">Render Resolution</InputLabel>
                    <Select value={renderResolution.toString()}
                      sx={{ marginLeft: "1vmin" }}
                      labelId="SelectLabel"
                      label="Render Resolution"
                      onChange={(event: SelectChangeEvent) => setRenderResolution(parseInt(event.target.value, 10))}>
                      {
                        supportedResolutions.map((val, i) => {
                          return (<MenuItem value={val} key={i}>{val}</MenuItem>);
                        })
                      }
                    </Select>
                  </Stack>
                  {anyFilesNeeded ?
                    <Typography
                      textAlign={"center"}
                      variant="h5"
                      component={"h2"}>Please add required pattern files
                    </Typography> : ""}
                  <br />
                  <Divider />
                  {
                    (!fabricObj || !projectData) ? "" : <ArahWeaveImageUpload
                      makeDragRefForImage={makeDragRefForImage}
                      fabricFile={fabricObj}
                      isDraggingFile={isDraggingFile}
                      onFileSelectionImage={onFileSelectionImage}
                      fabricUploadedData={projectData}

                    />
                  }
                  <Stack direction="row" sx={{ float: "right" }}>
                    <Button
                      sx={{ mx: "1vmin", my: "2vmin" }}
                      disabled={
                        isSubmitButtonDisabled
                        || (weaveImageNeeded || warpFilCoupeImageNeeded || weftFilCoupeImageNeeded)
                      }
                      onClick={onSubmitNewJob} color="success" variant="contained">
                      Submit
                    </Button>

                    <Button sx={{ mr: "0.5vmin", my: "2vmin" }} color="error" variant="contained" onClick={() => {
                      setIsModalOpen(false);
                      setProjectData(null);
                      setFabricObj(null);
                    }}>Cancel
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            </> : ""
        }
      </Stack>
    </>);
};
