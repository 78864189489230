import { LoginState } from "./";
import { CookieHelper, LocalStorage, ParseBool } from "./";

export class AuthHelper {
  private cookieHelper: CookieHelper;

  public constructor(cookieHelper: CookieHelper) {
    this.cookieHelper = cookieHelper;
  }

  public GetLoginState(): LoginState | null {

    const apiKey = this.cookieHelper.ReadCookie("ApiKey");
    const isAdminStr = LocalStorage.GetValue("IsAdmin");
    const email = LocalStorage.GetValue("Email");

    if (apiKey && isAdminStr && email) {

      return {
        apiKey: apiKey,
        isAdmin: ParseBool(isAdminStr),
        email: email
      };

    } else {
      return null;
    }

  }

  public IsLoggedIn(): boolean {
    return this.cookieHelper.IsLoggedIn();
  }

  public Login(apiKey: string, email: string, isAdmin: string): void {
    this.cookieHelper.CreateCookie("ApiKey", apiKey, 30);
    LocalStorage.SetValue("Email", email);
    LocalStorage.SetValue("IsAdmin", isAdmin);
  }

  public Logout(): void {
    this.cookieHelper.EraseCookie("ApiKey");
    LocalStorage.EraseValue("Email");
    LocalStorage.EraseValue("IsAdmin");
  }
}
