export class CookieHelper {
  private hostBase: string;

  public constructor(hostBase: string) {
    this.hostBase = hostBase;

  }
  public CreateCookie(name: string, value: string, days: number): void {
    let expires: string;
    if (days > 0) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    else {
      expires = "";
    }

    if (window.location.hostname === "localhost") {
      document.cookie = `${name}=${value}${expires}; path=/`;
    } else {
      document.cookie = `${name}=${value}${expires}; domain=.${this.hostBase};  path=/`;
    }
  }

  public EraseCookie(name: string): void {
    this.CreateCookie(name, "", -1);
  }

  public ReadCookie(name: string): string | null {
    const nameEQ = name + "=";
    const cookieParts = document.cookie.split(';');

    for (let i = 0; i < cookieParts.length; i++) {
      let cookiePart = cookieParts[i];

      while (cookiePart.charAt(0) === ' ') {
        cookiePart = cookiePart.substring(1, cookiePart.length);
      }

      if (cookiePart.indexOf(nameEQ) === 0) {
        return cookiePart.substring(nameEQ.length, cookiePart.length);
      }
    }
    return null;
  }

  public IsLoggedIn(): boolean {
    const apiKey = this.ReadCookie("ApiKey");

    return (apiKey !== null)
      && (apiKey !== undefined)
      && (apiKey !== "")
      && (apiKey !== "undefined");
  }
}
