import { To } from "react-router";
import { DEFAULT_PAGE } from "../pages/constants";
import { RenderAgentStatus, RenderJob, RenderJobStatus } from "./types";
import { Order } from "../components";

export const RenderJobStatusToName = (status: RenderJobStatus): string => {
  switch (status) {
    case RenderJobStatus.Submitted:
      return "Submitted";

    case RenderJobStatus.Assigned:
      return "Assigned";

    case RenderJobStatus.Queued:
      return "Queued";

    case RenderJobStatus.InProgress:
      return "In Progress";

    case RenderJobStatus.Complete:
      return "Complete";

    case RenderJobStatus.Crashed:
      return "Failed";

    case RenderJobStatus.Deleted:
      return "Deleted";

    case RenderJobStatus.Cancelled:
      return "Cancelled";

    case RenderJobStatus.Creating:
      return "Creating";
  }
};

export const RenderJobStatusToColor = (status: RenderJobStatus): "warning" | "error" | "info" | "success" => {
  switch (status) {
    case RenderJobStatus.Submitted:
      return "info";

    case RenderJobStatus.Assigned:
      return "info";

    case RenderJobStatus.Queued:
      return "info";

    case RenderJobStatus.InProgress:
      return "warning";

    case RenderJobStatus.Complete:
      return "success";

    case RenderJobStatus.Crashed:
      return "error";

    case RenderJobStatus.Deleted:
      return "success";

    case RenderJobStatus.Cancelled:
      return "success";

    case RenderJobStatus.Creating:
      return "info";
  }
};

export const IsNullOrEmpty = (str: string): boolean => {
  if (!IsNotNull(str)) {
    return true;
  } else if (str === "null") {
    return true;
  } else if (str === "") {
    return true;
  }

  return false;
};

export const IsNotNull = (obj: unknown): boolean => {
  return (obj !== null) && (obj !== undefined);
};

export const ArrayEquals = <T>(A: ArrayLike<T>, B: ArrayLike<T>): boolean => {

  if (A.length !== B.length) {
    return false;
  }

  let equals: boolean = false;

  for (let i = 0; i < A.length; i++) {
    equals = A[i] === B[i];
    if (!equals) {
      return false;
    }
  }

  return equals;
};

export const ParseBool = (val: string): boolean => {
  return (/true/i).test(val);
};

export const AgentStatusToString = (val: RenderAgentStatus): string => {
  switch (val) {
    case RenderAgentStatus.Crashed:
      return "Crashed";
    case RenderAgentStatus.Offline:
      return "Offline";
    case RenderAgentStatus.Ready:
      return "Ready";
    case RenderAgentStatus.Working:
      return "Working";
  }
};

export const AgentStatusToColor = (val: RenderAgentStatus): "warning" | "error" | "info" | "success" => {
  switch (val) {
    case RenderAgentStatus.Crashed:
      return "error";
    case RenderAgentStatus.Offline:
      return "info";
    case RenderAgentStatus.Ready:
      return "success";
    case RenderAgentStatus.Working:
      return "warning";
  }
};

export const SetCurrentPage = (
  val: number,
  numJobs:number,
  searchParams: URLSearchParams,
  pageSizeOptions: number[],
  defaultPageSize: number,
  navigateCallback: (to: To) => void): void => {
  if (val < 0) {
    val = 0;
  }

  const pageSize = GetPageSize(pageSizeOptions, defaultPageSize, searchParams);

  if (numJobs < 0 || val * pageSize > (numJobs - 1)) {
    const numPages = numJobs / pageSize;
    const frac = numPages - Math.floor(numPages);

    if (frac > 0) {
      val = numPages;
    } else {
      val = numPages - 1;
    }
  }

  const currentArgs = searchParams;
  currentArgs.set("page", val.toString());
  navigateCallback({
    path: location.pathname,
    search: currentArgs.toString()
  } as never);
};

export const GetPage = (
  numJobs: number,
  pageSizeOptions: number[],
  defaultPageSize: number,
  searchParams: URLSearchParams): number => {
  const pageParam = searchParams.get("page");
  if (pageParam) {
    let pageParamNumber = parseInt(pageParam);
    if (!isNaN(pageParamNumber) || pageParamNumber < 0) {

      const pageSize = GetPageSize(pageSizeOptions, defaultPageSize, searchParams);

      if (numJobs >= 0 && pageParamNumber * pageSize > (numJobs - 1)) {
        const numPages = numJobs / pageSize;
        const frac = numPages - Math.floor(numPages);

        if (frac > 0) {
          pageParamNumber = numPages - frac;
        } else {
          pageParamNumber = numPages - 1 - frac;
        }
      }

      searchParams.set("page", pageParamNumber.toString());
      return pageParamNumber;
    } else {
      return DEFAULT_PAGE;
    }
  } else {
    return DEFAULT_PAGE;
  }
};

export const SetPageSize = (
  val: number,
  pageSizeOptions: number[],
  defaultPageSize: number,
  searchParams: URLSearchParams,
  navigateCallback: (to: To) => void): void => {
  if (pageSizeOptions.findIndex((arrayVal) => arrayVal === val) === -1) {
    val = defaultPageSize;
  }

  const currentArgs = searchParams;
  currentArgs.set("pageSize", val.toString());
  navigateCallback({
    path: location.pathname,
    search: currentArgs.toString()
  } as never);
};

export const GetPageSize = (
  pageSizeOptions: number[],
  defaultPageSize: number,
  searchParams: URLSearchParams): number => {
  const pageSizeParam = searchParams.get("pageSize");
  if (pageSizeParam) {
    const pageSizeParamNumber = parseInt(pageSizeParam);
    const isValid = !isNaN(pageSizeParamNumber) &&
      pageSizeOptions.findIndex((arrayVal) => arrayVal === pageSizeParamNumber) !== -1;
    if (isValid) {

      return pageSizeParamNumber;
    } else {
      return defaultPageSize;
    }
  } else {
    return defaultPageSize;
  }
};

export const GetOrderByDirection = (searchParams: URLSearchParams, defaultOrder: Order): Order => {
  const sortRowDir = searchParams.get("sortRowDir");

  if (sortRowDir) {
    const toLower = sortRowDir.toLocaleLowerCase();

    if (toLower === "asc" || toLower === "desc") {
      return toLower;
    }
  }

  return defaultOrder;
};

export const GetOrderByIndex = (
  maxRows: number,
  defaultSortRow: number,
  searchParams: URLSearchParams): number => {
  const sortRowStr = searchParams.get("sortRow");
  if (sortRowStr) {
    let sortRow = parseInt(sortRowStr);
    if (!isNaN(sortRow)) {
      if (sortRow < 0) {
        sortRow = defaultSortRow;
      }
      else if (sortRow > maxRows) {
        sortRow = maxRows - 1;
      }

      return sortRow;
    }
  }
  return defaultSortRow;
};

export const SetOrderByIndex = (
  newIndex: number,
  maxRows: number,
  params: URLSearchParams,
  navigateCallback: (to: To) => void) => {
  if (newIndex > maxRows) {
    newIndex = maxRows - 1;
  }

  params.set("sortRow", newIndex.toString());
  navigateCallback({
    pathname: location.pathname,
    search: params.toString()
  });
};

export const SetOrderByDirection = (
  params: URLSearchParams,
  newOrder: Order,
  navigateCallback: (to: To) => void,
) => {

  params.set("sortRowDir", newOrder);

  navigateCallback({ pathname: location.pathname, search: params.toString() });
};

export const IsJobInEndState = (job: RenderJob): boolean => {
  return job.Status === RenderJobStatus.Complete ||
    job.Status === RenderJobStatus.Crashed ||
    job.Status === RenderJobStatus.Deleted;
};
