import {
  Box,
  Collapse,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { GuidHolder } from "../utils";
import { ReactNode } from "react";
import { OrderBy } from "./types";
import { ElipsisWrappedTypography } from "./ElipsisWrappedTypography";

interface ItemRowProps<T extends GuidHolder> {
  readonly Item: T,
  readonly TableColumnData: TableColumnData<T>[],
  readonly ExpandedRow: string | null | undefined,
  readonly ExpandedNode: ReactNode | undefined
};

const ItemRow = <T extends GuidHolder>(props: ItemRowProps<T>) => {
  const { Item, ExpandedRow, ExpandedNode, TableColumnData } = props;

  return (
    <>
      <TableRow>
        {TableColumnData.map((column, index) => {
          return (<TableCell key={index}>{column.ValueGetter(Item)}</TableCell>);
        })}
      </TableRow>
      {
        ExpandedNode ?
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={TableColumnData.length}>
              <Collapse in={ExpandedRow ? ExpandedRow === Item.id : false} timeout={1}>
                {ExpandedNode}
              </Collapse>
            </TableCell>
          </TableRow> : ""
      }
    </>
  );
};

interface TableControlProps {
  readonly Page: number,
  readonly PageSize: number,
  readonly PageSizeOptions: number[],
  readonly NumTotalItems: number,
  readonly SetPage: (val: number) => void,
  readonly SetPageSize: (val: number) => void,

}

const TableControls = (props: TableControlProps) => {
  const { PageSizeOptions, PageSize, NumTotalItems, Page, SetPage, SetPageSize } = props;
  const controls =
    <Stack direction="row">

      <TablePagination
        rowsPerPageOptions={PageSizeOptions}
        rowsPerPage={PageSize}
        component="div"
        count={NumTotalItems}
        page={Page}
        onPageChange={(_, newPage) => SetPage(newPage)}
        onRowsPerPageChange={(evt) => SetPageSize(+evt.target.value)}
        showFirstButton
        showLastButton />
    </Stack>;
  return controls;
};

export interface TableColumnData<T extends GuidHolder> {
  readonly Index: number,
  readonly PropertyName?: string,
  readonly DisplayName?: string,
  readonly ExpandButton?: boolean,
  readonly Sortable: boolean,
  readonly ValueGetter: (item: T) => ReactNode,
  readonly Width: number | string
}

interface SortableSearchableTableProps<T extends GuidHolder> {
  readonly Page: number,
  readonly PageSize: number,
  readonly SetPage: (page: number) => void,
  readonly SetPageSize: (pageSize: number) => void,
  readonly PageSizeOptions: number[],
  readonly NumTotalItems: number,
  readonly DataItems: T[],
  readonly TableColumnData: TableColumnData<T>[],
  readonly ExpandedRow?: string | null | undefined,
  readonly ExpandedContent?: ReactNode | undefined,
  readonly OnClickSort: (index: number) => void,
  readonly OrderBy: OrderBy,
  readonly HeaderFontSize?: string | undefined,
  readonly ControlsAtTop?: boolean,
  readonly [rest: string]: unknown
}
export const SortableSearchableTable = <T extends GuidHolder>(props: SortableSearchableTableProps<T>) => {

  const {
    NumTotalItems,
    Page,
    PageSize,
    PageSizeOptions,
    SetPage,
    SetPageSize,
    DataItems,
    TableColumnData,
    ExpandedRow,
    ExpandedContent,
    OnClickSort,
    OrderBy,
    HeaderFontSize,
    ControlsAtTop,
    ...rest } = props;

  const getHeaderRow = (config: TableColumnData<T>) => {
    return !config.ExpandButton
      ? config.Sortable ?
        <TableSortLabel
          direction={OrderBy.Dir}
          active={config.Index === OrderBy.Index}
          onClick={() => OnClickSort(config.Index)}>
          <ElipsisWrappedTypography
            fontSize={HeaderFontSize}
            Width={config.Width}>{
              config.DisplayName}
          </ElipsisWrappedTypography>
        </TableSortLabel>
        :
        <ElipsisWrappedTypography Width={config.Width}>{config.DisplayName}</ElipsisWrappedTypography>

      : "";
  };

  const noData = DataItems.length === 0;
  const SkeletonRow = () =>
    <>
      <Skeleton sx={{ my: "1vh" }} variant="rectangular" animation="wave" width="100%" height="3vh" />
    </>;

  return (
    <>
      {
        ControlsAtTop ?
          <TableControls
            NumTotalItems={NumTotalItems}
            Page={Page}
            PageSize={PageSize}
            PageSizeOptions={PageSizeOptions}
            SetPageSize={SetPageSize}
            SetPage={SetPage} /> : ""
      }
      <Box {...rest}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {
                  TableColumnData.map((config) => {
                    return <TableCell sx={{ paddingX: config.ExpandButton ? config.Width : "" }} width={config.Width} key={config.Index}>{getHeaderRow(config)}</TableCell>;
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                DataItems.map((item) => {
                  return <ItemRow
                    key={item.id}
                    Item={item}
                    TableColumnData={TableColumnData}
                    ExpandedRow={ExpandedRow}
                    ExpandedNode={ExpandedContent} />;
                })
              }
            </TableBody>

          </Table>
          <Stack spacing={1}>

            {
              noData ? [...Array(30)].map((_, i) => { return <SkeletonRow key={i} />; }) : ""
            }
          </Stack>
        </TableContainer>
      </Box>
      {
        !ControlsAtTop ?
          <TableControls
            NumTotalItems={NumTotalItems}
            Page={Page}
            PageSize={PageSize}
            PageSizeOptions={PageSizeOptions}
            SetPageSize={SetPageSize}
            SetPage={SetPage} /> : ""
      }
    </>);

};
