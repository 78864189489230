import moment from 'moment';
import { ReactNode } from 'react';
import { ElipsisWrappedTypography } from './ElipsisWrappedTypography';

interface TimeSinceProps {
  readonly Date: Date,
  readonly Width: string | number,
  readonly [rest: string]: unknown
}

export const TimeSince = (props: TimeSinceProps): ReactNode => {
  const { Date, Width, ...rest } = props;
  return (<ElipsisWrappedTypography {...rest} Width={Width}>{happenedRecently(Date) ? "never" : moment(Date).fromNow()}</ElipsisWrappedTypography>);

};

const happenedRecently = (date: Date): boolean => {
  const numYears = 100;
  const since = numYears * 1000 * 60 * 60 * 24 * 365;

  return (new Date().getTime() - new Date(date).getTime()) > since;
};
