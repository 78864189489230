export const EMAIL_REGEX
  = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const CURRENT_GEM_FILE_MAJOR: number = 0;
export const CURRENT_GEM_FILE_MINOR: number = 0;
export const GEM_FILE_MAGIC: number[] =
  [0x46,
    0x41,
    0x42,
    0x52,
    0x49,
    0x43,
    0x0,
    0x0];

export const DEFAULT_PAGE: number = 0;
