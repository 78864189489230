import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthHelper, CookieHelper } from './utils/';
import { HomePage, LoginPage, SignUpPage, AdminPage } from './pages';

const App = () => {

  let uriBase: string;
  if (window.location.hostname === "localhost") {
    // uriBase = "http://localhost:7071/api/";
    uriBase = "https://app.gemell.io/api/";

  } else {
    uriBase = "https://app.gemell.io/api/";
  }

  const hostBase = "gemell.io";
  const cookieHelper = new CookieHelper(hostBase);
  const authHelper = new AuthHelper(cookieHelper);;

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const darkTheme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
    },
  });

  const router = createBrowserRouter([{
    path: "/",
    element: <HomePage authHelper={authHelper} baseApiUrl={uriBase} />,
  }, {
    path: "/Login",
    element: <LoginPage authHelper={authHelper} baseApiUrl={uriBase} />,
  }, {
    path: "/LoginDevice",
    element: <LoginPage
      deviceLogin
      authHelper={authHelper} baseApiUrl={uriBase} />
  }, {
    path: "/Register",
    element: <SignUpPage authHelper={authHelper} baseApiUrl={uriBase} />,
  }, {
    path: "/Admin",
    element: <AdminPage authHelper={authHelper} baseApiUrl={uriBase} />
  }]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>

  );
};

export default App;
