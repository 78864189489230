import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";

interface PasswordHideAdornmentProps {
  readonly hidePassword: boolean,
  readonly setHidePassword: (state: boolean) => void;
}

export const PasswordHideAdornment = (props: PasswordHideAdornmentProps) => {
  const { hidePassword, setHidePassword } = props;
  return (
    <InputAdornment position="end">
      <IconButton tabIndex={-1} edge="end" onClick={() => setHidePassword(!hidePassword)}>
        {
          hidePassword
            ? <Visibility />
            : <VisibilityOff />
        }
      </IconButton>
    </InputAdornment>);
};
