import { Alert, Box, Button, Collapse, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { PasswordHideAdornment } from "../components/";
import { Navigate } from "react-router";
import { EMAIL_REGEX } from "./constants";
import { AccountCreationError, ApiEnabledPage, LoginParams } from "./types";
import { useSearchParams } from "react-router-dom";

export interface SignUpPageProps extends ApiEnabledPage {
}

export const SignUpPage = (props: SignUpPageProps) => {
  const { baseApiUrl, authHelper } = props;
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const [badPassword, setBadPassword] = useState<boolean>(false);
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState<boolean>(false);
  const [badEmail, setBadEmail] = useState<boolean>(false);

  const [passwordValue, setPasswordValue] = useState<string>("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");

  const [passwordHelperText, setPasswordHelperText] = useState<string>("");
  const [passwordConfirmedHelperText, setPasswordConfirmedHelperText] = useState<string>("");
  const [emailHelperText, setEmailHelperText] = useState<string>("");

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);

  const [isAccountCreationFailed, setIsAccountCreationFailed] = useState<boolean>(false);
  const [accountCreationError, setAccountCreationError] = useState<AccountCreationError>(AccountCreationError.Success);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(authHelper.IsLoggedIn());

  const [searchParams, ] = useSearchParams();

  const onPasswordChanged = (input: React.ChangeEvent<HTMLInputElement>) => {
    const password = input.target.value;
    setPasswordValue(password);
  };

  const onConfirmPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordConfirm = event.target.value;
    setPasswordConfirmValue(passwordConfirm);
  };

  const onEmailChanged = (input: React.ChangeEvent<HTMLInputElement>) => {
    const email = input.target.value;
    setEmailValue(email);
  };

  const checkEmail = (email: string): boolean => {
    if (!email) {
      setEmailHelperText("Please enter an email address");
      return false;
    }

    const match = email.match(EMAIL_REGEX);
    if (!match) {
      setEmailHelperText("Please enter a valid email address");
      return false;
    } else {
      const matched = match.length > 0;
      if (matched) {
        setEmailHelperText("");
      } else {
        setEmailHelperText("Please enter a valid email address");
      }
      return matched;
    }
  };

  const checkPassword = (password: string): boolean => {

    if (!password) {
      setPasswordHelperText("Please enter a password");
      return false;
    }

    if (password.length < 8) {
      setPasswordHelperText("Use 8 characters or more for your password");
      return false;
    }

    const upper = password.match("[A-Z]+");
    const lower = password.match("[a-z]+");
    const number = password.match("[0-9]+");

    const upperCount = upper ? upper.length : 0;
    const lowerCount = lower ? lower.length : 0;
    const numberCount = number ? number.length : 0;

    const noLower = lowerCount === 0;
    const noUpper = upperCount === 0;
    const noNumbers = numberCount === 0;
    const passwordSting = "Please enter a password with at least one lower case character, upper case character and a number";

    if (noLower || noUpper || noNumbers) {
      setPasswordHelperText(passwordSting);
      return false;
    }

    setPasswordHelperText("");

    return true;
  };

  const onFormSubmitted = async () => {

    const isbadPassword = !checkPassword(passwordValue);
    const isbadEmail = !checkEmail(emailValue);

    setBadPassword(isbadPassword);
    setBadEmail(isbadEmail);

    const mismatchedPasswords = passwordConfirmValue !== passwordValue;

    if (mismatchedPasswords) {
      setPasswordConfirmedHelperText("Passwords do not match");
      setPasswordsDoNotMatch(true);
    } else {
      setPasswordConfirmedHelperText("");
      setPasswordsDoNotMatch(false);
    }

    if (isbadPassword || isbadEmail || mismatchedPasswords) {
      return;
    }

    setSubmitButtonDisabled(true);

    const data: LoginParams = {
      Email: emailValue,
      Password: passwordValue,
    };

    const deviceId = searchParams.get("device");
    const secret = searchParams.get("secret");

    if (deviceId && secret) {
      data.DeviceId = deviceId;
      data.ClientSecret = secret;
    }

    const registerResponse = await fetch(baseApiUrl + "Register", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (registerResponse.status === 200) {
      const json = await registerResponse.json();
      const redirectUri = searchParams.get("redirectUri");

      if (redirectUri) {
        const redirRes = await fetch(`${redirectUri}?key=${json.apiKey}`);

        if (redirRes && redirRes.status === 200) {
          authHelper.Login(
            json.apiKey,
            json.email,
            json.isAdmin);
          setIsLoggedIn(true);
          setIsAccountCreationFailed(false);
        }

      } else {
        authHelper.Login(
          json.apiKey,
          json.email,
          json.isAdmin);
        setIsLoggedIn(true);
        setIsAccountCreationFailed(false);
      }

    } else if (registerResponse.status === 403) {
      const json = await registerResponse.json();

      setAccountCreationError(json.error);
      setIsAccountCreationFailed(true);
    }

    setSubmitButtonDisabled(false);

  };

  const onEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onFormSubmitted();
    }
  };

  if (isLoggedIn) {
    return <Navigate replace to="/" />;
  }
  else {

    return (
      <Box className="loginSignupBox">
        <Stack minWidth="18em" maxWidth="18em">
          <Typography variant="h4">Sign-up</Typography>
          <br />
          <TextField
            error={badEmail}
            helperText={emailHelperText}
            sx={{ "mb": "0.5vh" }}
            onKeyDown={onEnterKey}
            label="Email"
            autoComplete="email"
            onChange={onEmailChanged}
            required />

          <TextField
            error={badPassword}
            helperText={passwordHelperText}
            label="Password"
            onKeyDown={onEnterKey}
            onChange={onPasswordChanged}
            type={hidePassword ? "password" : "text"}
            autoComplete="new-password"
            required
            InputProps={{
              endAdornment: <PasswordHideAdornment hidePassword={hidePassword} setHidePassword={setHidePassword} />,
            }}
            sx={{ "mb": "0.75vh" }}
          />
          <TextField label="Confirm Password" type={hidePassword ? "password" : "text"} required
            sx={{ "mb": "0.75vh" }}
            InputProps={{
              endAdornment: <PasswordHideAdornment hidePassword={hidePassword}
                setHidePassword={setHidePassword} />,
            }}
            onKeyDown={onEnterKey}
            onChange={onConfirmPasswordChanged}
            autoComplete="new-password"
            error={passwordsDoNotMatch}
            helperText={passwordConfirmedHelperText}
          />
          <Button
            disabled={submitButtonDisabled}
            onClick={onFormSubmitted}
            variant="contained" title="Next">Next
          </Button>
          <Collapse sx={{ marginTop: "0.5vh" }} in={isAccountCreationFailed}>
            <Alert variant="outlined" severity="error">
              {accountCreationError === AccountCreationError.EmailInUse
                ? "Account already exists with provided email."
                : "Device already registered to another account."}
            </Alert>
          </Collapse>
        </Stack>
      </Box>);
  }
};

export default SignUpPage;
