export interface LoginState {
  apiKey: string,
  email: string,
  isAdmin: boolean
}

export enum RenderAgentStatus {
  Crashed,
  Offline,
  Ready,
  Working
}

export interface GuidHolder {
  id: string,
}

export interface RenderAgent extends GuidHolder {
  LastPing: Date,
  Name: string
  Status: RenderAgentStatus,
  ApiKey: string,
  MaxQueuedJobs: number,
  pk: number
}
export enum RenderJobStatus {
  Submitted,
  Assigned,
  Queued,
  InProgress,
  Complete,
  Crashed,
  Deleted,
  Cancelled,
  Creating
}

export interface RenderJob extends GuidHolder {
  AssignedAgent: string,
  Submitted: Date,
  Assigned: Date,
  Queued: Date,
  Started: Date,
  Crashed: Date,
  Cancelled: Date,
  Deleted: Date,
  Name: string,
  Resolution: number,
  Owner: string,
  Status: RenderJobStatus,
  StatusMessage: string,
  Completion: number,
  JobData: string
  pk: number

}

export interface UserAccount extends GuidHolder {
  Group: string,
  Email: string,
  ApiKey: string
  ApiKeyExpiry: Date,
  Licenses: string[]
  IsAdmin: boolean
  pk: number
}

export const FindItemWithGuid = <T extends GuidHolder>(guid: string, agents: GuidHolder[]): T | null => {

  const results = agents.filter((account) => {
    if (account.id === guid) {
      return account;
    }
  });

  if (results.length === 1) {
    return results[0] as T;
  } else {
    return null;
  }
};
