import { AppBar, Box, Button, Chip, IconButton, Toolbar, } from "@mui/material";
import { useNavigate } from "react-router";
import { AuthHelper } from "../utils";
import { LogoutOutlined } from "@mui/icons-material";

interface TopBarProps {
  readonly logout: () => void,
  readonly authHelper: AuthHelper
}

export const TopBar = (props: TopBarProps) => {

  const { logout, authHelper } = props;
  const loginState = authHelper.GetLoginState();
  const navigate = useNavigate();
  const logoutButton =
    <IconButton onClick={() => {
      if (authHelper.IsLoggedIn()) {
        authHelper.Logout();
        logout();
      }
    }} title="Logout" aria-label="logout" size="large">

      <Chip icon={<LogoutOutlined />} label={loginState?.email} />

    </IconButton>;

  return (
    <Box className="topBarRoot">
      <AppBar position="static" >
        <Toolbar disableGutters={true} sx={{ flexDirection: "row-reverse" }}>
          {logoutButton}
          {loginState?.isAdmin ? <Button variant="contained" onClick={() => navigate("/Admin")}>Admin</Button> : ""}
        </Toolbar>
      </AppBar>
    </Box>);
};
