export const LocalStorage = {
  SetValue: (name: string, value: string): void => {
    localStorage.setItem(name, value);
  },
  GetValue: (name: string): string | null => {
    return localStorage.getItem(name);
  },
  EraseValue: (name: string): void => {
    localStorage.removeItem(name);
  }
};
